<template>
  <b-row cols-sm="1" cols-xl="2">
    <b-col>
      <c-card title="Daftar">
        <pagination-table ref="table" endpoint="v1/format_transactions/index" :fields="fields">
          <template v-slot:id="{item}">
            <b-button-group size="sm">
              <b-button variant="primary" :to="{name:'numberings-show',params:{id:item.id}}"><feather-icon icon="EyeIcon"></feather-icon></b-button>
            </b-button-group>
          </template>
        </pagination-table>
      </c-card>
    </b-col>
    <b-col>
      <router-view :key="$route.path"></router-view>
    </b-col>
  </b-row>
</template>

<script>
import PaginationTable from '@/utils/components/PaginationTable.vue'
import CCard from '@/utils/components/CCard.vue'
import {BButtonGroup,BButton} from 'bootstrap-vue'

export default {
  components:{
    CCard,
    PaginationTable,
    BButtonGroup,
    BButton
  },
  data(){
    const fields = [
      {key:'name',label:'Nama Lengkap',sortable:true},
      {key:'id',label:'',tdClass:'d-flex justify-content-end'},
    ]
    return {
      fields
    }
  }
}
</script>

<style>

</style>